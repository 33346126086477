import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { CSSTransition } from 'react-transition-group';

import { isBrowser, isProduction } from '@helpers/utils';

import * as styles from '@styles/modules/common.module.scss';

const marketoScriptId = 'mktoForms';

const MarketoForm = ({ formId, prefill, redirect, submit, noRefresh }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const loadScript = () => {
    const s = document.createElement('script');
    s.id = marketoScriptId;
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//go.himama.com/js/forms2/js/forms2.min.js';
    s.onreadystatechange = function () {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        setIsLoaded(true);
      }
    };
    s.onload = () => setIsLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(s);
  };
  const checkForRedirect = () => {
    if (redirect) {
      // Marketo causes a page refresh unless redirect uses location.href
      location.href = redirect;
    }
  };
  useEffect(() => {
    if (!document.getElementById(marketoScriptId) && isBrowser()) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);
  useEffect(() => {
    isLoaded &&
      window.MktoForms2.loadForm(
        '//go.himama.com',
        '184-QZH-075',
        formId,
        function(form) {
          // Stops spinner when loaded
          if (!isRendered) {
            window.MktoForms2.whenRendered(() => {
              setIsRendered(true)
            });
          }
          // Prefill form values
          if (form && prefill) {
            form.vals({...prefill});
            // Submit form on load
            if (submit) {
              form.submit();
            }
          }
          // Add an onSuccess handler
          form.onSuccess((values, followUpUrl) => {
            Cookies.set('_hm_templates', true);
            checkForRedirect();
            if (noRefresh) {
              return false;
            }
          });
        }
      );
  }, [isLoaded, formId]);
  useEffect(() => {
    if (isLoaded) {
      const form = window.MktoForms2.getForm(formId);
      if (form) {
        form.vals({...prefill});
      }
    }
  }, [prefill]);
  return (
    <div className={clsx('d-flex align-items-center justify-content-center position-relative', {[styles.loader]:!isRendered})}>
      <Spinner
        animation='border'
        role='status'
        className={clsx(styles.spinner, {'d-none':isRendered})}
      >
        <span className='sr-only sr-only-focusable'>Loading...</span>
      </Spinner>
      <CSSTransition
        classNames={`fade`}
        in={isRendered}
        timeout={300}
      >
        <form id={`mktoForm_${formId}`}></form>
      </CSSTransition>
    </div>
  )
}

export default MarketoForm
